<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新建试卷库' : '修改试卷库'"
    width="484"
    destroy-on-close
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="paperBankForm" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="名称" prop="name">
        <el-input v-model="paperBankForm.name" placeholder="请输入试卷库名称" />
      </el-form-item>
      <el-form-item label="封面" prop="cover">
        <div class="custom-upload" :class="{ uploaded: uploadSuccess }">
          <Upload
            ref="upload"
            :show-upload-list="false"
            :on-success="handleUploadCoverSuccess"
            :on-progress="handleUploadProgress"
            :before-upload="beforeUpload"
            :on-exceeded-size="handleExceededSize"
            :data="uploadParams"
            :format="['jpg', 'jpeg', 'png']"
            :max-size="1024"
            type="drag"
            :action="uploadUrl"
          >
            <Progress v-if="isUploading" :percent="percentage" hide-info />
            <div class="upload">
              <el-image v-if="!isUploading && uploadSuccess" :src="paperBankForm.photoUrl"></el-image>
              <svg v-if="!uploadSuccess" class="icon" aria-hidden="true">
                <use xlink:href="#icon-xinzeng" />
              </svg>
            </div>
          </Upload>
          <div class="form-tip">
            支持 jpg/gif/png 格式，不超过 10M
          </div>
        </div>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input type="textarea" v-model="paperBankForm.description" :rows="12" placeholder="请输入试卷库描述" />
        <div class="form-tip" v-if="!ruleValidate.description.error">
          不超过200个字
        </div>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" @click="submit" :loading="loading">确定 </el-button>
    </div>
  </el-dialog>
</template>
<script>
import paperApi from '@api/paper'
import ossApi from '@api/oss'

export default {
  data() {
    const that = this
    return {
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      percentage: 0,
      uploadUrl: '',
      serveLoca: '',
      paperBank: {},
      uploadSuccess: false,
      isUploading: false,
      visible: false,
      mode: true,
      categoryId: null,
      loading: false,
      paperBankForm: {
        name: '',
        description: '',
        photoUrl: ''
      },
      ruleValidate: {
        name: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.trim() === '') {
              that.ruleValidate.name.error = true
              callback(new Error('试卷库名称不为空'))
            } else if (value.length > 30) {
              that.ruleValidate.name.error = true
              callback(new Error('试卷库名称不超过30个字'))
            } else {
              that.ruleValidate.name.error = false
              callback()
            }
          }
        },
        description: {
          type: 'string',
          error: false,
          validator: (rule, value, callback) => {
            if (value.length > 200) {
              that.ruleValidate.description.error = true
              callback(new Error('试卷库描述不超过 200 字'))
            } else {
              that.ruleValidate.description.error = false
              callback()
            }
          }
        }
      }
    }
  },
  methods: {
    open(data) {
      this.visible = true
      this.categoryId = data
    },
    updatePaperBank(row) {
      this.visible = true
      this.mode = false
      this.paperBank = row
      this.paperBankForm.name = row.name
      this.paperBankForm.description = row.description
      this.paperBankForm.photoUrl = row.photoUrl
    },
    handleUploadCoverSuccess(response, file) {
      this.uploadSuccess = true
      this.isUploading = false
      if (window.uploadUrl) {
        this.paperBankForm.photoUrl = this.serveLoca
      } else {
        this.paperBankForm.photoUrl = `${this.uploadUrl}/${this.uploadParams.key}`
      }
    },
    beforeUpload(file) {
      if (window.uploadUrl) {
        //
        let forData = new FormData()
        forData.append('file', file)
        ossApi.upCover(forData).then(res => {
          this.serveLoca = res.data.res
          this.handleUploadCoverSuccess()
        })
      } else {
        ossApi.upType().then(() => {
          ossApi.getQuestionBankCoverToken(new Date().valueOf(), file.name).then(res => {
            const data = res.res
            this.uploadUrl = `https://${data.host}`
            this.uploadParams.key = data.dir
            this.uploadParams.OSSAccessKeyId = data.accessId
            this.uploadParams.policy = data.policy
            this.uploadParams.Signature = data.signature
            let formData = new FormData()
            formData.append('key', this.uploadParams.key)
            formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
            formData.append('policy', this.uploadParams.policy)
            formData.append('Signature', this.uploadParams.Signature)
            formData.append('file', file)
            fetch(`https://${data.host}`, {
              method: 'POST',
              body: formData
            }).then(data => {
              this.handleUploadCoverSuccess()
            })
          })
        })
      }
      return false
    },
    handleExceededSize() {
      this.$message.error('图片大小不超过 10M')
    },
    handleUploadProgress(event, file) {
      this.isUploading = true
      this.percentage = file.percentage
    },
    submit() {
      if (this.mode) {
        this.create()
      } else {
        this.update()
      }
    },
    closed() {
      this.$refs.form.resetFields()
      this.visible = false
      this.mode = true
      this.isUploading = false
      this.loading = false
      this.uploadSuccess = false
    },
    close(done) {
      done()
    },
    create() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let paperBankInfo = Object.assign(this.paperBankForm)
          paperBankInfo.categoryId = this.categoryId
          paperApi
            .addPaperBank(paperBankInfo)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('新建成功')
                this.$emit('on-success')
                this.$refs.form.resetFields()
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    update() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let paperBankInfo = Object.assign(this.paperBankForm)
          paperBankInfo.id = this.paperBank.paperBankId
          paperBankInfo.categoryId = this.categoryId
          paperApi
            .modifyPaperBank(paperBankInfo)
            .then(res => {
              if (res.res === 1) {
                this.$message.success('修改成功')
                this.$emit('on-success')
                this.$refs.form.resetFields()
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.paper-modal ::v-deep .ivu-modal {
  .ivu-modal-body {
    .custom-upload {
      .ivu-upload-drag {
        width: 124px;

        img {
          width: 124px;
          height: 90px;
        }
      }

      &.uploaded {
        .ivu-upload-drag {
          border: none;
          display: inline;

          &:hover {
            border: none;
          }
        }
      }
    }

    .upload {
      width: 124px;
      height: 90px;
      font-size: 60px;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #b8c2d1;
      }
    }
  }
}
::v-deep .yt-dialog-form {
  text-align: left;
}
::v-deep .ivu-upload-drag {
  width: 157px;
}
</style>
